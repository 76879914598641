/* GENERAL CONFIGS */

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px auto;
}

.row {
  padding: 0 18px;
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1024px;
  border-radius: 4px;
}

.checkIcon {
  text-align: center;
  color: green;
  padding: 0 10px 0 0;
}

.errorIcon {
  text-align: center;
  color: red;
  padding: 0 10px 0 0;
}

.subTitle {
  color: #fff;
  text-align: center;
  margin: 40px 0 20px 0;
  font-size: 45px;
}

.description {
  text-align: center;
  margin: 10px 0 30px;
  color: #fff;
  font-size: 16px;
}

/* ROW HOME TOP */

.benefitList ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.benefitList li {
  color: #fff;
  padding: 10px 0;
  font-size: 22px;
  margin-left: 10px;
}

#rowBanner h1 {
  color: #fff;
  text-align: center;
  font-size: 35px;
  margin: 25px 0;
}

#rowBanner h1 > span {
  color: green;
}

#rowBanner a {
  margin-top: 30px;
  background-color: green;
  color: #fff;
  border-radius: 4px;
}

#rowBanner a:hover {
  color: green;
  background-color: #fff;
}

.buyButton {
  border: 0;
  width: 100%;
  height: 100%;
  background-color: green;
  color: #fff;
  font-size: 35px;
  padding: 10px 0;
  border-radius: 4px;
  font-weight: bold;
  transition: all 0.3s;
}

.buyButton:hover {
  color: green;
  background-color: #fff;
}

#rowBanner img {
  width: 400px;

  @media (max-width: 768px) {
    width: 300px;
  }
}

.homeBannerTop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

/* ROW DIFFERENCES */

#rowDifferences {
  color: red;
}

.containerDifferences {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 880px) {
    flex-direction: column;
  }
}

.containerDifferences section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 880px) {
    margin: 20px 0;
  }
}

.containerDifferences section div {
  justify-content: space-around;
  align-content: center;
  align-items: center;
}

.containerDifferences section ul {
  list-style: none;
  align-items: center;
  margin-left: 15px;
}

.containerDifferences section li {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.containerDifferences section img {
  align-items: center;
}

.imageSlider {
  width: 100%;
  max-width: 700px;
}

/* ROW TESTIMONY */

.containerTestimony {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.containerTestimony img {
  width: 40%;
}

/* ROW RESULTS */

#rowResults {
  margin-top: 30px;
  background-color: #fdef83;
}

#rowResults .subTitle {
  color: #333;
  font-size: 45px;
  margin-bottom: 0;
}

#rowResults .subTitle span {
  color: greenyellow;
  background-color: #333;
  padding: 2px 4px;
  border-radius: 3px;
}

.termsAlert {
  color: #333;
  font-size: 12px;
  font-weight: normal;
}

#rowResults .description {
  color: #333;
  font-size: 28px;
  font-weight: bold;
}

.containerResults {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 880px) {
    flex-direction: column;
  }
}

.containerResults section {
  justify-content: space-around;
}

.containerResults img {
  width: 50%;
  align-items: center;
}

.warrantySeal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.warrantySeal img {
  width: 50%;
}

/* ROW SHIPPING */
#rowShipping {
  padding-top: 30px;
  background-color: #fff;
}

#rowShipping .subTitle {
  color: #333;
  margin-bottom: 0;
  font-size: 35px;
}

#rowShipping .subTitle span {
  color: greenyellow;
  background-color: #333;
  padding: 2px 4px;
  border-radius: 3px;
}

#rowShipping .description {
  color: #333;
  font-size: 22px;
  font-weight: bold;
}

.shippingContent {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: center;

  @media (max-width: 880px) {
    flex-direction: column;
  }
}

.shippingContent img {
  width: 50%;
  margin: 0 auto;
  display: table;
}

.shippingContent p {
  text-align: center;
  color: #333;
  font-size: 25px;
  padding: 10px 0;
}

/* ROW PRODUCTS */
.containerProducts {
  display: flex;
  align-items: end;
  height: 500px;

  @media (max-width: 980px) {
    height: auto;
    flex-direction: column;
    align-items: center;
  }
}

#rowProducts {
}
